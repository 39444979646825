






































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue, IMedia } from "@/Services/Interfaces";
import { globalUpdateDetailService } from "@/Services/GlobalService";
import BaseSubComponent from "./BaseSubComponent.vue";
import ActionComponent from "./ActionComponent.vue";

@Component({ components: { BaseSubComponent, ActionComponent } })
export default class MovieComponent extends BaseSubComponent {
  constructor() {
    super();
    this.name = "MovieComponent";
    this.thismodel = <IStrDetailValue>{};
  }

  name: string;

  @Prop()
  medias: IMedia[] | undefined;

  thismodel: IStrDetailValue;

  getMovieHTML(item: IMedia): string {
    return (
      " <iframe style='margin-left: 70px;' height='200' src=" +
      this.getMovie(item) +
      " frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe> "
    );
  }
  getMovie(item: IMedia): string {
    return item.link;
  }

  hasMedias(): boolean {
    return this.getMedias().length > 0;
  }

  getMedias(): IMedia[] {
    if (!this.medias) return [];
    return this.medias.filter((t) => t.typ && t.typ.toLowerCase() == "movie");
  }

  IsMultiMovie(): boolean {
    return this.thismodel && this.medias != undefined && this.medias.length > 1;
  }

  doMovis(): void {
    var url =
      "//stefhorst.de/Diashow.html?imageurl=" +
      this.thismodel.memovalue;
    window.open(url, "diashow", 'noreferrer');
  }

  onUpdate(model: any) {
    if (this.canUpdate(model)) {
      {
        globalUpdateDetailService.sendMessage(model);
      }
    }
  }

  data() {
    return {
      thismodel: this.model,
    };
  }

  computed() {
    // this.model.memovalue =      "http://stefhorst.de/images/gifs/210/62316_WP_20181026_005.gif";
  }
}
